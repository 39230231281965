export function formatDateUtil(time, pattern) {
  const _pattern = pattern || 'yyyy-MM-dd'
  const date = new Date(time)
  if (date.toString() === 'Invalid Date') {
    // console.error('日期转换失败')
    return ''
  }
  const timeObj = {
    yyyy: date.getFullYear(),
    MM: `0${date.getMonth() + 1}`.slice(-2),
    M: date.getMonth() + 1,
    dd: `0${date.getDate()}`.slice(-2),
    d: date.getDate(),
    hh: `0${date.getHours()}`.slice(-2),
    h: date.getHours(),
    mm: `0${date.getMinutes()}`.slice(-2),
    m: date.getMinutes(),
    ss: `0${date.getSeconds()}`.slice(-2),
    s: date.getSeconds()
  }
  const timeStr = _pattern.replace(
    /(yyyy|MM|M|dd|d|hh|h|mm|m|ss|s)+/g,
    function (match, p) {
      const value = timeObj[p]
      return value
    }
  )
  return timeStr
}
