<template>
  <div>
    <div class="current-period">
      <div class="c-block">
        <span>当前：</span>
        <span>第{{ currentPeriod.num }}期</span>
        <el-button
          v-if="!editFlag"
          type="primary"
          size="small"
          @click="editPeriod"
          style="margin-left: 20px"
          >修改日期</el-button
        >
        <el-button
          v-else
          type="primary"
          size="small"
          @click="confirmEdit"
          style="margin-left: 20px"
          >确认</el-button
        >
        <el-button
          v-if="editFlag"
          type="primary"
          size="small"
          @click="editFlag = false"
          style="margin-left: 20px"
          >取消</el-button
        >
      </div>
      <div class="c-block">
        <span>开始日期：</span>
        <span v-if="!editFlag">{{ formatDate(currentPeriod.start_date) }}</span>
        <el-date-picker v-else v-model="editForm.start_date"></el-date-picker>
      </div>
      <div class="c-block">
        <span>结束日期：</span>
        <span v-if="!editFlag">{{ formatDate(currentPeriod.end_date) }}</span>
        <el-date-picker v-else v-model="editForm.end_date"></el-date-picker>
      </div>
    </div>
    <div class="next-period" v-if="nextPeriod">
      <div class="c-block">
        <span>未开始：</span>
        <span>第{{ nextPeriod.num }}期</span>
        <el-button
          type="primary"
          size="small"
          @click="startPeriod"
          style="margin-left: 20px"
          >开始本周期</el-button
        >
      </div>
      <div class="c-block">
        <span>开始日期：</span>
        <span>{{ formatDate(nextPeriod.start_date) }}</span>
      </div>
      <div class="c-block">
        <span>结束日期：</span>
        <span>{{ formatDate(nextPeriod.end_date) }}</span>
      </div>
    </div>
    <div v-else style="margin-top: 50px">
      <el-button type="primary" size="medium" @click="createPeriod"
        >新建周期</el-button
      >
    </div>
    <el-dialog v-model="dialogVisible" width="600px">
      <el-form :model="newPeriodData" label-width="80px">
        <el-form-item label="期数" prop="num">
          <el-input
            disabled
            v-model="newPeriodData.num"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="start_date">
          <el-date-picker
            v-model="newPeriodData.start_date"
            style="width: 50%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="end_date">
          <el-date-picker
            v-model="newPeriodData.end_date"
            style="width: 50%"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div style="margin-top: 40px; text-align: right">
        <el-button size="medium" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="medium"
          style="margin-left: 10px"
          @click="submit"
          :loading="submitLoading"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  getPeriod,
  createNewPeriod,
  startNewPeriod,
  editCurrentPeriod
} from '@/api'
import { onMounted, reactive, ref } from 'vue'
import { myError, mySuccess } from '@/hooks/useMessage'
import { formatDateUtil } from '@/utils/util'
import { ElMessageBox } from 'element-plus'

const currentPeriod = ref({})
const nextPeriod = ref(null)
const getData = () => {
  getPeriod()
    .then((res) => {
      if (res.code === 200) {
        currentPeriod.value = res.data.find((item) => item.is_current)
        nextPeriod.value = res.data.find((item) => !item.is_used)
      } else {
        myError('获取当前周期失败')
      }
    })
    .catch(() => {
      myError('获取当前周期失败')
    })
}

const formatDate = (date) => {
  return formatDateUtil(new Date(date))
}

const dialogVisible = ref(false)
const newPeriodData = reactive({
  num: '',
  start_date: '',
  end_date: '',
  is_current: false,
  is_used: false
})
const createPeriod = () => {
  newPeriodData.num = currentPeriod.value.num + 1
  dialogVisible.value = true
}
const submitLoading = ref(false)
const submit = () => {
  if (
    !newPeriodData.num ||
    !newPeriodData.start_date ||
    !newPeriodData.end_date
  ) {
    myError('请填写的日期')
    return
  }
  submitLoading.value = true
  createNewPeriod(newPeriodData)
    .then((res) => {
      if (res.code === 200) {
        getData()
        newPeriodData.num = ''
        newPeriodData.start_date = ''
        newPeriodData.end_date = ''
        newPeriodData.is_current = false
        newPeriodData.is_used = false
        dialogVisible.value = false
        mySuccess('操作成功')
      } else {
        myError('操作失败')
      }
    })
    .catch(() => {
      myError('操作失败')
    })
    .finally(() => {
      submitLoading.value = false
    })
}

const startPeriod = () => {
  ElMessageBox.confirm(
    '开始本周期后，上一个周期会自动关闭，是否确认开始',
    '警告',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
      draggable: true
    }
  ).then(() => {
    startNewPeriod(currentPeriod.value.id, nextPeriod.value.id)
      .then((res) => {
        if (res.code === 200) {
          getData()
          mySuccess('操作成功')
        } else {
          myError('操作失败')
        }
      })
      .catch(() => {
        myError('操作失败')
      })
  })
}

const editForm = reactive({
  start_date: '',
  end_date: ''
})
const editFlag = ref(false)
const editPeriod = () => {
  editFlag.value = true
  editForm.start_date = currentPeriod.value.start_date
  editForm.end_date = currentPeriod.value.end_date
}

const confirmEdit = () => {
  if (!editForm.start_date || !editForm.end_date) {
    myError('请选择日期')
  } else {
    editCurrentPeriod(
      currentPeriod.value.id,
      editForm.start_date,
      editForm.end_date
    )
      .then((res) => {
        if (res.code === 200) {
          editFlag.value = false
          getData()
          mySuccess('操作成功')
        } else {
          myError('操作失败')
        }
      })
      .catch(() => {
        myError('操作失败')
      })
  }
}

onMounted(() => {
  getData()
})
</script>

<style scoped lang="scss">
.current-period,
.next-period {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
  .c-block {
    margin-top: 20px;
  }
}
</style>
